@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Suisse';
  src: url('../public/fonts/Suisse/SuisseRegular.otf');
  font-display: swap;
}

@font-face {
  font-family: 'SuisseBook';
  src: url('../public/fonts/Suisse/SuisseBook.otf');
  font-display: swap;
}

@font-face {
  font-family: 'SuisseBold';
  src: url('../public/fonts/Suisse/SuisseBold.otf');
  font-display: swap;
}

@font-face {
  font-family: 'SuisseSemibold';
  src: url('../public/fonts/Suisse/SuisseSemibold.otf');
  font-display: swap;
}

@layer base {
  h1 {
    @apply font-semibold text-m-h1 lg:text-h1;
  }
  h2 {
    @apply font-semibold text-m-h2 lg:text-h2;
  }
  h3 {
    @apply text-h3;
  }
  h4 {
    @apply font-bold text-m-h4 lg:text-h4;
  }
  h5 {
    @apply font-semibold text-m-h5 lg:text-h5;
  }
  p {
    @apply text-base;
  }
  label {
    @apply text-h5;
  }
  input {
    @apply bg-[right_8px_center] bg-no-repeat;
    &:disabled {
      @apply border-grey-lightest bg-grey-wash text-ink-light;
    }
    &.input-error {
      @apply bg-inputError;
    }
    &.input-success {
      @apply bg-inputValid;
    }
  }
  .error {
    @apply float-left text-input-error text-red;
  }
  .paragraph-1 {
    @apply text-m-lead lg:text-lead;
  }
  .paragraph-2 {
    @apply text-m-standard lg:text-long-form;
  }
  .paragraph-3 {
    @apply text-m-standard lg:text-standard;
  }
  .paragraph-4 {
    @apply text-m-small-body lg:text-small-body;
  }
  a {
    @apply font-medium text-blue no-underline hover:text-indigo hover:underline;
  }
  .desktop-images {
    @apply col-start-1 row-start-1 w-full;
  }
  .landing-page-title {
    @apply text-m-h2 text-green lg:text-h3;
  }
  .landing-page-subtitle {
    @apply text-m-lead text-ink-light lg:text-h3 lg:text-black;
  }
}

@layer components {
  .btn {
    @apply mt-3 h-16 w-full rounded-l-full rounded-r-full bg-blue font-bold text-white sm:mt-8 lg:mt-12;
    &:disabled {
      @apply bg-grey;
    }
    &:enabled:hover {
      @apply bg-indigo;
    }
    &:focus {
      @apply border-[3px] border-solid border-blue-light;
    }
    &:active {
      @apply scale-95;
    }
  }
  .btn-outline {
    @apply mt-12 h-16 w-full rounded-l-full rounded-r-full border-[3px] border-blue bg-transparent font-bold text-blue;
    &:disabled {
      @apply border-grey-lightest text-grey-lightest;
    }
    &:enabled:hover {
      @apply bg-blue text-white;
    }
  }
  .banner {
    @apply m-auto mb-8 flex h-[108px] w-full items-center rounded-xl bg-teal-wash  py-4 pl-6 text-grey shadow-sm;
  }
  .progress-container {
    @apply rounded-lg bg-white;
    .progress-line {
      @apply h-2 rounded-2xl bg-blue delay-300 duration-1000 ease-in;
    }
  }
  .link {
    @apply font-medium text-blue no-underline hover:text-indigo hover:underline;
  }
  fieldset {
    &.input-error {
      input {
        @apply border-2 border-red;
      }
    }
  }
  input.code-single-input {
    @apply h-[60px] w-[45px] rounded border-2 border-blue p-3 text-center text-m-h2 md:w-[6rem] lg:text-h3;
    &:disabled {
      @apply border-grey-lightest bg-grey-wash text-ink-light;
    }
  }
}

@layer utilities {
  .active {
    @apply font-semibold text-blue;
  }
  .inactive {
    @apply text-grey;
  }
  .success {
    @apply font-semibold text-green;
  }
  .form-max-width {
    @apply mx-auto max-w-screen-formContainer lg:w-formContainer;
  }
}

/**
 * Added to make the bottom chrome on ios safari visible all the time because
 * of the "dead zone" that safari adds.
 *
 * https://www.eventbrite.com/engineering/mobile-safari-why/
 */
html,
body {
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
}
/**
 * Do not show arrows on numberic inputs
 */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
