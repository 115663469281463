.Icon.small::before,
.Icon.medium::before,
.Icon.large::before {
  /** center icon (unless it's type inherit) **/
  display: inline-block;
  text-align: center;
}

.Icon.small::before {
  font-size: 1rem;
  line-height: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
}

.Icon.medium::before {
  font-size: 1.5rem;
  line-height: 2rem;
  min-width: 2rem;
  min-height: 2rem;
}
.Icon.large::before {
  font-size: 2rem;
  line-height: 2.5rem;
  min-width: 2.5rem;
  min-height: 2.5rem;
}
